$.dialog = {
   dialogAttr: null,
   registerCallback: null,

   show: function() {
      var dialog = $("#dialog");

      dialog.modal("show");

      $.nette.load();
      if ($.dialog.registerCallback) {
         $.dialog.registerCallback(dialog);
      }

      dialog.on("hidden.bs.modal", function(e) {
         dialog.remove();
      });
   },

   close: function() {
      $("#dialog").modal("hide");
   },

   builder: function(data) {
      if (data.substr(0, 1) == "{") {
         // JSON data
         alert("not implemented JSON");
         return;
      }

      $("body").prepend(data);
      $.dialog.show();
   },

   click: function(url, event) {
      $.get(
         url,
         function(data) {
            $.dialog.builder(data);
         },
         "html"
      );
   },

   register: function(scope, registerCallback) {
      $.dialog.registerCallback = registerCallback;
      scope.on("click", ".dialog", function(event) {
         event.preventDefault();
         var el = $(event.target);
         $.dialog.dialogAttr = el.attr("dialog-attr");
         $.dialog.click(el.attr("href"), event);
      });
   }
};

$.brabo = {
   // kaskadni skryvani selectboxu s hromadnymi akcemi
   bulkActionsVisibility: function(scope) {
      scope.find(".bulk-control[data-parent]").each(function() {
         var item = $(this);
         var parent = $("select[name='" + item.attr("data-parent") + "']");
         var parentKey = item.attr("data-parent-key");

         var visible = parent.is(":visible") && parent.val() == parentKey;

         if (visible) {
            item.show();
         } else {
            item.hide();
         }
      });
   },

   listFilters: function(scope) {
      scope.find("table.list-filtered").each(function() {
         var item = $(this).find("th:last");
         item.addClass("filter");
         item.append('<a href="#" class="filter"><i class="ico-bars"></i></a>');
      });

      scope.find("a.filter").on("click", function(e) {
         e.preventDefault();
         var filter = $(this)
            .closest("table.list")
            .prev();
         filter.toggle("fast");
      });
   },

   register: function(scope) {
      $.brabo.bulkActionsVisibility(scope);
      $.brabo.listFilters(scope);
      scope.find("select.reload-component").on("change", function(e) {
         $(this)
            .closest("form")
            .find("input.reload-button")
            .click();
      });
   },

   // string to number conversion
   ton: function(str) {
      if (typeof str == "undefined") {
         return 0;
      }
      return Number(String(str).replace(",", "."));
   }
};
